
<template>
  <b-modal
      id="editServiceModal"
      ref="modal"
      title="Edit Service"
      @show="resetModal"
      @hidden="resetModal"
      hide-footer
    >
      <form ref="form" @submit.prevent="update_service">
        <b-alert show variant="danger" v-if="error" class="text-center">{{msg}}</b-alert>
        <b-form-group
          label="Title"
          label-for="name-input"
          invalid-feedback="Title is required"
        >
          <b-form-input
            id="name-input"
            v-model="service_title"
            :class="{ 'is-invalid': isValid && $v.service_title.$error }"
          ></b-form-input>
        </b-form-group>
        <b-form-group
                label="Hourly Rate"
                label-for="name-input"
                invalid-feedback="Hourly Rate is required"
        >
          <b-form-input
                  id="name-input"
                  type="number"
                  min="1"
                  v-model="service_hourly_rate"
                  :class="{ 'is-invalid': isValid && $v.service_hourly_rate.$error }"
          ></b-form-input>
        </b-form-group>

      <b-button variant="primary" v-on:click="update_service">Update</b-button>
      </form>
    </b-modal>
</template>
<script>

import { serviceMethods } from "@/state/helpers";
import { required } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      msg:'',
      error: false,
      isValid: false,
      service_title : '',
      service_hourly_rate : '',
      service_id:0,
    };
  },

  props: {
    selected_title: {
      type: String,
    },
    selected_hourly_rate: {
      type: String,
    },
    selected_id:{
      type: Number,
    }
  },
  validations: {
    service_title: {
      required
    },
    service_hourly_rate: {
      required
    },
  },
  methods: {
    ...serviceMethods,
    // reset all fields of modal on click
    resetModal(){
    this.service_title=this.selected_title;
    this.service_hourly_rate=this.selected_hourly_rate;
    this.service_id=this.selected_id;
    this.msg='';
    this.error=false;
    this.isValid = false;
    },
    // hit api to update service
    update_service() {
      this.msg='';
      this.isValid = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
          return;
      }
     this.updateService({id:this.service_id,title:this.service_title,hourly_rate:this.service_hourly_rate}).then(res => {
      this.$refs['modal'].hide();
      if (res.status == 200) {
        this.msg = res.message ? res.message : "Service updated successfully"
         this.$swal({
          icon : 'success',
          text: this.msg,
          toast:true,
          showCloseButton: false,
           showConfirmButton: false,
           timer: 3000
         }).then(
        this.getAllServices(),
         );
      }
      }).catch(error => {
        this.error = true;
        if (error.data.status == 422) {
          this.msg = error.data.errors.title[0] ? error.data.errors.title[0] : "Something went wrong!!!"
        }
        else{
        this.$swal({
            icon : 'error',
            toast : true,
            text: "Failed to save! Try again",
          showCloseButton: false,
          showConfirmButton: false,
          timer: 3000
        });
        }
      })
    },
  },
  mounted(){

  }
};
</script>
