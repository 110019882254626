
<template>
      <b-modal
      id="addServicesModal"
      ref="modal"
      title="Add Service"
      @show="resetModal"
      @hidden="resetModal"
      hide-footer
    >
      <form ref="form" @submit.prevent="add_service">
        <b-alert show variant="danger" v-if="error" class="text-center">{{msg}}</b-alert>
        <b-form-group
          label="Title"
          label-for="name-input"
          invalid-feedback="Title is required"
        >
          <b-form-input
            id="name-input"
            v-model="service_title"
            :placeholder="modal_placeholder"
            :class="{ 'is-invalid': isValid && $v.service_title.$error }"
          ></b-form-input>
        </b-form-group>

          <b-form-group
                  label="Hourly Rate"
                  label-for="name-input"
                  invalid-feedback="Hourly Rate is required"
          >
              <b-form-input
                      id="name-input"
                      min="1"
                      type="number"
                      v-model="service_hourly_rate"
                      :placeholder="modal_placeholder"
                      :class="{ 'is-invalid': isValid && $v.service_hourly_rate.$error }"
              ></b-form-input>
          </b-form-group>
      <b-button variant="primary" v-on:click="add_service">Submit</b-button>
      </form>
     </b-modal>
</template>

<script>
import { serviceMethods } from "@/state/helpers";
import { required } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      msg : "",
      error:false,
      isValid: false,
      service_title : '',
      service_hourly_rate : '',
    };
  },
  props: {
    modal_placeholder: {
      type: String,
    }
  },
  validations: {
    service_title: {
      required
    },
    service_hourly_rate: {
      required
    },
  },
  methods: {
    ...serviceMethods,
    resetModal(){
      this.service_title='';
      this.service_hourly_rate='';
      this.msg='';
      this.error=false;
      this.isValid = false;
    },
    add_service() {
      this.msg='';
      this.isValid = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
          return;
      }
      this.addService({title:this.service_title, hourly_rate:this.service_hourly_rate}).then(res => {
        this.$refs['modal'].hide();
        if (res.status == 200) {
          this.msg = res.message ? res.message : "Service added successfully"
          this.$swal({
              icon : 'success',
              toast : true,
              text: this.msg,
            showCloseButton: false,
              showConfirmButton: false,
              timer: 3000
          }).then(
          this.getAllServices(),
          );
        }
      }).catch(error => {
        this.error = true;
        if (error.data.status == 422) {
          this.msg = error.data.errors.title[0] ? error.data.errors.title[0] : "Something went wrong!!!"
        }
        else{
        this.$swal({
            icon : 'error',
            toast : true,
            text: "Failed to save! Try again",
          showCloseButton: false,
            showConfirmButton: false,
            timer: 3000
        });
        }
        });
    },
  }
};
</script>
